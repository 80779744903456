.p-toast-message-text {
  @apply font-main;
  font-size: 1.25rem;
  margin: 0 1rem !important;
}

.p-toast-message-icon {
  display: none;
}

.p-toast-icon-close:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 87, 36, 0.5) !important;
}

/* si => sidebar */

.si-close {
  @apply flex lg:hidden my-8 bg-primary rounded-full p-4 w-1 h-1 justify-center items-center text-white cursor-pointer;
}
.si-nav-max-w {
  width: calc(100% - 250px) !important;
}
.si-nav-min-w {
  width: calc(100% - 80px) !important;
}
.si-nav {
  @apply bg-pageBackground h-[40px] lg:h-[40px] flex  mr-0 lg:mr-auto text-black pr-0 lg:pr-4 py-8  items-center;
}

.si-max-w {
  @apply w-[250px] !important;
}
.si-min-w {
  @apply w-[80px] !important;
}

.si-nav-menu {
  @apply flex flex-row justify-between gap-8 items-center;
}

.si-nav-user {
  @apply h-[30px] rounded-lg w-auto lg:min-w-[150px] flex flex-row items-center justify-center gap-4 bg-transparent lg:bg-primary;
}

.si-nav-u-title {
  @apply hidden lg:block;
}

.si-nav-notices {
  @apply relative;
}

.si-nav-c-notices {
  @apply absolute w-[20px] h-[20px] rounded-full bg-red-500 text-white text-sm top-[-.5rem] right-[-.4rem] text-center;
}

.si-nav-img-u {
  @apply w-[20px] h-[20px] rounded-full overflow-clip;
}
.si-nav-img-u img {
  @apply w-full h-[inherit];
}

.si {
  @apply bg-pageBackground lg:bg-inputBackground w-[250px] h-screen flex justify-center fixed top-0  transition-all z-10 overflow-hidden pr-4 lg:pr-8 ease-in-out duration-150;
}

.si-logo {
  @apply h-[60px] w-[200px] p-0;
}

.si-wrap {
  @apply w-full;
}

.si-link {
  @apply mr-8 mt-4 text-sm h-[80px] flex justify-start items-center;
}

/* su => subMenu */

.su-link {
  @apply flex text-black font-bold justify-between items-center  list-none h-[40px] text-lg mb-4 px-3;
}

.su-title {
  @apply mr-4;
}

/* b => box collection */

.b-collection {
  @apply flex flex-col  shadow-md  gap-4 w-full py-4 px-2;
  align-items: center;
  background: url(../../assets/icons/box_collection_bg.png);
}

.b-count {
  @apply flex flex-row justify-start items-center gap-8;
  flex-direction: column;
}
.b-count-number {
  color: #000;

  font-family: 'Sakkal Majalla';
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.b-title {
  color: #000;
  font-family: 'Sakkal Majalla';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 133.333% */
}

@media (max-width: 800px) {
  .si-hide-media {
    @apply right-[-100%];
  }

  .si-show-media {
    @apply right-0;
  }
  .si-nav-max-w {
    width: 100% !important;
  }
}

/* ad-l admin layout */

.ad-l {
  @apply ml-2 px-2 mr-auto;
  width: calc(100% - 280px);
}

@media (max-width: 800px) {
  .ad-l {
    width: 100%;
  }
}

/* dt => dashboard table */

.d-t {
  @apply w-full p-0 text-center;
}

.d-t button {
  @apply font-main;
}

.d-t table {
  @apply w-full;
}

.d-t .p-card-body,
.d-t .p-card .p-card-content {
  @apply p-0;
}

.d-t table,
.d-t th,
.d-t td {
  @apply p-4 text-center font-main text-xl;
}

.d-t table tr:nth-child(odd) {
  @apply bg-adminMenuBackground;
}

.d-t table thead th {
  @apply bg-white border-none;
}

.d-t table tbody {
  @apply border-2;
}

/* d-c => dashboard Chart */

.d-c {
  @apply shadow-md my-8;
  width: 100%;
}

.d-c-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-c-title {
  color: #000;
  font-family: 'Sakkal Majalla';
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.d-c-chart-desc {
  background-color: lightgreen;
}

.d-c .inputDate {
  @apply w-full lg:w-auto border-[1px] rounded-md border-primary p-3 px-6;
  direction: ltr;
}

.d-c .btn {
  @apply w-full lg:w-auto;
}

.d-c .inputDate:focus {
  @apply shadow-none outline-none;
}

.d-c .p-button:focus {
  @apply shadow-none;
}

.d-c .p-button:enabled:hover,
.p-button:not(button):not(a):not(.p-disabled):hover {
  @apply bg-primary border-none;
}

.d-c .field {
  @apply flex flex-row gap-4 items-center;
}

.d-c .p-datepicker-trigger {
  @apply border-none text-primary;
}

.subMenuLink {
  width: 90%;
  border-radius: 8px;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  padding: 12px 16px 12px 12px;
  transition: all 0.3s;
  color: #ffffff;
  opacity: 0.55;
}
.activeSubMenuLink {
  color: white;
  background: #ffffff33;
  opacity: 1;
}
.center-chart-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 555px;
}

.center-chart-content img {
  width: 300px;
  height: 300px;
}

@media (max-width: 800px) {
  .center-chart-content {
    width: 300px;
    height: 300px;
  }

  .center-chart-content img {
    width: 250px;
    height: 250px;
  }
}

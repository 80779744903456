/* q-f-r => question for review */

.q-f-r-header {
  @apply hidden lg:flex gap-3 items-center mb-5;
}

.q-f-r-title {
  @apply text-primary text-lg md:text-2xl lg:text-3xl font-bold border-b-4 border-primary self-start;
}

.dialog {
  @apply lg:m-5 w-full md:w-1/2 font-main;
}

@media (max-width: 800px) {
  .dialog {
    @apply fixed bottom-0;
  }
}

/* a => account-verification */

.a-p {
  @apply mt-2 mb-6 text-gray-800 font-main text-sm md:text-xl;
}

.a-error {
  @apply text-sm text-red-600 inline-block;
}

.a-link {
  @apply text-primary underline font-main;
}
.verifyInput {
  width: 100% !important;
}
.verifyInput div {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  direction: ltr;
}

.verifyInput input {
  @apply bg-gray-200 !rounded-md shadow-md !border-0 !w-[40px] !h-[40px] lg:!w-[58px] lg:!h-[54px] mx-1 lg:!mx-0;
}

/*  f => form */

.f-input {
  @apply bg-white rounded-xl ring-1 ring-primary md:bg-inputBackground md:border-none md:rounded-none shadow-none font-main my-1 md:ring-0;
}

.f-error {
  @apply text-sm text-red-600 inline-block font-main;
}

input.p-filled + label,
input:focus + label {
  top: -0.25rem !important;
}

.qLink {
  background: rgb(0, 78, 40);
  color: white;
  width: 70px;
  height: 30px;
  font-size: 20px;
  margin: 5px;
  border-radius: 5px;
  text-align: center;
}

/* c-q => create-question  */

.c-q {
  @apply grid grid-cols-1 lg:grid-cols-2;
}

@media (max-width: 900px) {
  .c-q {
    background: linear-gradient(#014e28e5 100%, #014e28 100%), url('../../assets/images/create_question_image.jpeg');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.c-q-content {
  @apply p-4 py-10 lg:p-10;
}

.c-q-form-p {
  @apply mt-3 text-2xl text-white lg:text-black;
}

.c-q-form {
  @apply flex flex-col items-start gap-3 mt-7;
}

.c-q-form-field {
  @apply flex flex-col w-full gap-1;
}

.c-q-form-label {
  @apply text-xl text-white lg:text-black;
}

.c-q-form-input {
  @apply bg-[#f9f9f9] rounded-md shadow-md p-1 w-full lg:w-1/2;
}

.c-q-form-textarea-container {
  @apply bg-[rgb(249,249,249)] rounded-md shadow-md p-1 w-full lg:w-11/12;
  
}

.c-q-form-textarea {
  @apply bg-[rgb(249,249,249)] w-full;
  padding:1dvh
}

.c-q-bg-img {
  @apply hidden h-full lg:flex;
}

.c-q-card {
  @apply mt-10;
}

.c-q-container {
  @apply px-8 py-8 lg:p-10;
}

.c-q-title-card {
  @apply flex items-center gap-3;
}

@media (max-width: 900px) {
  .c-q-title-tip {
    @apply flex gap-3 pt-4 text-xl font-bold text-black lg:text-white;
  }
  .c-q-icon {
    @apply flex items-center justify-center w-6 h-6 text-white bg-black rounded-full lg:hidden;
  }
  .p-tooltip-arrow {
    @apply hidden;
  }

  .p-tooltip .p-tooltip-text {
    @apply w-0 h-0 p-0 !bg-transparent;
  }
  .c-q-tooltip > svg {
    @apply !fill-white;
  }

  .c-q-tooltip {
    @apply fixed bottom-0 w-full text-black h-1/2 rounded-t-md bg-white px-3 text-lg font-main left-0;
  }
}

/* cl => consultation list */

.cl-wrapper {
  @apply flex flex-col md:flex-row gap-4 my-10 pt-4;
}

.cl-inner {
  @apply order-2 md:order-1 w-full md:w-3/4 bg-white my-4 shadow-sm rounded-2xl;
}

.cl-btn {
  @apply border-t-[.2px] border-[rgba(112,112,112,0.24)] flex justify-center items-center p-16;
}

.cl-p {
  @apply hidden md:block pt-0 font-main font-thin;
}

.p-tabview .p-tabview-nav {
  @apply bg-inputBackground md:bg-white rounded-md md:rounded-none text-center md:text-right flex justify-evenly md:justify-start;
}

.p-tabview-nav-link {
  @apply !border-none font-main font-thin text-xl;
}

.p-unselectable-text .p-tabview-nav-link:hover,
.p-tabview-nav-link:focus {
  @apply !border-none !shadow-none;
}

.p-tabview-selected {
  @apply border-b-8 border-primary rounded-b-md;
}

.p-tabview-nav {
  @apply !border-none;
}

@media (max-width: 767px) {
  .p-tabview-nav-container {
    @apply mx-4 shadow-md rounded-xl overflow-hidden;
  }
  .p-tabview .p-tabview-nav {
    width: 100%;
    @apply flex items-baseline justify-center;
  }
  .p-tabview .p-tabview-nav li {
    width: 100%;
    border: none;
  }

  .p-unselectable-text .p-tabview-nav-link {
    @apply !bg-inputBackground !text-primary;
  }
  .p-tabview-selected .p-tabview-nav-link {
    @apply !bg-primary !text-white;
  }
  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    @apply py-6;
  }
}

/*  vi-c => ViewConsultation */

.vi-c-title {
  @apply text-4xl font-bold w-fit text-primary border-b-8 border-primary;
}

.vi-c-content {
  @apply flex flex-row gap-4 my-8 w-full;
}

.vi-c-info {
  @apply flex flex-row items-center gap-4 justify-between;
}

.vi-c-user-img {
  @apply flex flex-row items-center gap-4;
}

.vi-c-img {
  @apply w-[40px] h-[40px]  rounded-full overflow-hidden border-2 border-primary;
}

.vi-c-body {
  @apply shadow-md md:shadow-none bg-pageBackground p-4 md:p-8 rounded-md w-full;
}

.vi-c-m {
  @apply flex flex-col gap-8 my-6 w-full;
}

.vi-c-border {
  @apply border-r-4 border-primary md:border-none pr-2;
}

.vi-c-text p {
  @apply text-2xl font-bold leading-loose;
}

.vi-c-m-title {
  @apply text-4xl font-bold font-main;
}

.vi-btn {
  @apply flex flex-row justify-center gap-4 my-16;
}

.vi-btn-media {
  @apply md:hidden bg-primary/10 rounded-md p-3 ml-0 md:ml-16 flex justify-center items-center;
}

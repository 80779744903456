/* q-b => question bank list */

.q-b-d .p-dialog-header-icons {
  @apply hidden;
}

.q-b-line {
  @apply mx-auto w-[100px] h-[5px] rounded-lg bg-black flex justify-center items-center;
}

@media (max-width: 800px) {
  .q-b-d .p-calendar .p-component {
    @apply bg-inputBackground border-0 shadow-md mb-2;
  }
}

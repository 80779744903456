@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF-Mada';
  src: url('./assets/fonts/SFMada.woff2') format('woff2'), url('./assets/fonts/SFMada.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SF-Mada';
  src: url('./assets/fonts/SF-Mada-Bold.woff2') format('woff2'), url('./assets/fonts/SF-Mada-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sakkal Majalla';
  src: url('./assets/fonts/SakkalMajalla.woff2') format('woff2'),
    url('./assets/fonts/SakkalMajalla.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sakkal Majalla';
  src: url('./assets/fonts/SakkalMajalla-Bold.woff2') format('woff2'),
    url('./assets/fonts/SakkalMajalla-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE SS Text';
  src: url('./assets/fonts/GESSTextLight-Light.woff2') format('woff2'),
    url('./assets/fonts/GESSTextLight-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE SS Text';
  src: url('./assets/fonts/GESSTextMedium-Medium.woff2') format('woff2'),
    url('./assets/fonts/GESSTextMedium-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GE SS Text';
  src: url('./assets/fonts/GESSTextBold-Bold.woff2') format('woff2'),
    url('./assets/fonts/GESSTextBold-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  @apply font-main;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  min-height: 100vh;
}

:root {
  direction: rtl;
}

/* Hide arrows from input number in Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows from input number in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.p-datatable-table {
  border-top: none;
}

.p-datatable-tbody tr:not(.p-row-odd) {
  background-color: #eeeeee;
}

.p-datatable-table {
  @apply font-main;
  font-size: large;
}

/* Override PrimeReact Menu styles */
.p-menu .p-menuitem-link:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(21, 87, 36, 0.5) !important;
}

.p-menu .p-menuitem-link .p-menuitem-icon {
  margin-right: 0 !important;
  margin-left: 0.5rem !important;
}

.p-menuitem-text {
  @apply font-main;
  font-size: large;
}

/* Override PrimeReact MenuBar styles */
.p-menubar-end {
  margin-left: 0 !important;
  margin-right: auto !important;
}

/* Override PrimeReact DataTable Paginator */
.lc-table .p-paginator.p-component.p-paginator-bottom {
  @apply !block;
}

.lc-table .p-paginator.p-component.p-paginator-bottom .p-paginator-pages {
  @apply float-left mx-2;
}

/* Override PrimeReact DataTable text align */
.p-datatable .p-datatable-tbody > tr > td,
.p-datatable .p-datatable-thead > tr > th {
  @apply text-right;
}

/* Override PrimeReact MultiSelect styles */
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  @apply mr-0 ml-2;
}
.p-multiselect-items-label,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item > span {
  @apply font-main;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  @apply bg-primaryLight;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: inset 0 0 0 0.15rem rgba(21, 87, 36, 0.5) !important;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-primary;
}

.lc-checkbox {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  line-height: 1.5rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.lc-checkbox-green {
  border: 2px solid #014e28;
}

.lc-checkbox-black {
  border: 2px solid black;
}

.lc-checkbox:hover {
  background-color: #eee;
}

.lc-checkbox-green:checked {
  background-color: #014e28;
}

.lc-checkbox-black:checked {
  background-color: black;
}

.lc-checkbox-green:checked:hover {
  background-color: #007239;
  border-color: #007239;
}

.lc-checkbox-black:checked:hover {
  background-color: #444;
  border-color: #444;
}

.lc-checkbox:checked:after {
  content: '\2714';
  color: white;
  font-size: 1rem;
  position: relative;
  bottom: 4px;
  right: 1px;
}

.chart-row {
  display: flex;
  height: 24px;
  width: 540px;
  align-items: center;
  margin: 8px 0px;
  font-family: Sakkal Majalla, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.chart-row .success {
}

.chart-row .title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  width: 30%;
}

.chart-row .progress {
  position: relative;
  width: 50%;
  height: 4px;
  flex-shrink: 0;
  overflow: hidden;
  margin: 0px 29px;
  border-radius: 26px;
}

.chart-row .progress .bg {
  position: absolute;
  opacity: 0.3;
  width: 100%;
  height: 4px;
  border-radius: 26px;
  z-index: 1;
}

.chart-row .progress .value {
  position: absolute;
  height: 4px;
  border-radius: 26px;
  z-index: 10;
}
.chart-row .progress .bg.darkGreen,
.chart-row .progress .value.darkGreen {
  background: #024e28;
}
.chart-row .progress .bg.success,
.chart-row .progress .value.success {
  background: #34c759;
}
.chart-row .progress .bg.warning,
.chart-row .progress .value.warning {
  background: #fab048;
}

.chart-row .progress .bg.error,
.chart-row .progress .value.error {
  background: #bc4c0d;
}

.chart-row .percent {
  display: flex;
  width: 50px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;

  text-align: center;
  font-family: 'Open Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  justify-content: center;
  align-items: center;
}
.chart-row .percent.darkGreen {
  border: 1px solid #024e28;
  color: #024e28;
}
.chart-row .percent.success {
  border: 1px solid #34c759;
  color: #34c759;
}
.chart-row .percent.warning {
  border: 1px solid #fab048;
  color: #fab048;
}
.chart-row .percent.error {
  border: 1px solid #bc4c0d;
  color: #bc4c0d;
}

.chartTable {
}

.chartTable .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Sakkal Majalla';
  font-size: 20px;
  border-bottom: 1px solid #edf2f6;
  padding-bottom: 12px;
  margin-bottom: 20px;
}
.chartTable .header {
  display: flex;
}
.chartTable .header .title {
  flex: 1;
  color: #000;
  font-family: 'Sakkal Majalla';
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.chartTable .header .date {
  display: flex;
  align-items: center;
}
.chartTable .header .date span {
  direction: rtl;
}

.chartTable .top .title {
  /* flex: 1; */
}
.chartTable .top .percent {
}

@media (max-width: 1500px) {
  .chart-row {
    width: 470px;
    height: auto;
  }
}
@media (max-width: 800px) {
  .chart-row {
    width: 300px;
    height: auto;
  }
}

.buttons-container {
  display: flex;
  width: 472px;

  border-radius: 8px;
  border: 0.8px solid #000;
  align-items: center;
  justify-content: space-evenly;
  overflow: hidden;
}

.buttons-container .button {
  flex: 1;
  width: 2000px;
  padding: 8px;
  background: white;
  cursor: pointer;
  text-align: center;
}
.buttons-container > .button.active {
  background: rgba(2, 78, 40, 0.2);
}

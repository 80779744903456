/* Fix margin between year and month in the header */
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-left: 0.5rem !important;
  margin-right: 0 !important;
}

/* Increate year and month text font size */
.p-datepicker-title span.p-datepicker-month,
.p-datepicker-title span.p-datepicker-year {
  font-size: 1.5rem;
}

/* Flip next and prev arrows to work with our global direction: rtl */
.p-datepicker-next-icon.pi.pi-chevron-right::before {
  content: '\e900' !important;
}

.p-datepicker-prev-icon.pi.pi-chevron-left::before {
  content: '\e901' !important;
}

/* Remove padding from footer */
.p-datepicker-buttonbar {
  padding: 0 !important;
}

/* New styles when having date icon */
.p-calendar-w-btn-left .p-inputtext {
  border-radius: 4px 0 0 4px !important;
  border-right: none;
}

.p-calendar-w-btn-left .p-datepicker-trigger {
  border-radius: 0 4px 4px 0 !important;
  border: 1px solid #ced4da;
  border-left: none;
  color: black;
}

.p-calendar-w-btn-left .p-datepicker-trigger:hover {
  background-color: #474747;
}

.p-calendar-w-btn-left .p-datepicker-trigger:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 71, 71, 0.5) !important;
}

/* Override selected day color and focus */
.p-datepicker-calendar .p-highlight,
.p-datepicker-calendar .p-highlight span {
  background: #014e28 !important;
}

.p-datepicker-calendar .p-highlight:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 87, 36, 0.5) !important;
}

/* Override next and prev arrows focus */
button.p-datepicker-next.p-link:focus,
button.p-datepicker-prev.p-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 87, 36, 0.5) !important;
}

/* Override input focus */
.p-calendar.p-component.p-inputwrapper input.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 87, 36, 0.5) !important;
  border-color: #014e28 !important;
}

/* Apply main font to Calendar components */
.p-datepicker,
.p-datepicker-buttonbar .p-button-label.p-c {
  @apply font-main;
}

@media (max-width: 900px) {
  .p-menuitem {
    color: black !important;
  }
  .p-menubar-button {
    @apply block   focus:rounded-none focus:!shadow-none focus-visible:rounded-none focus-visible:outline-none;
  }

  .nav-transparent .p-menubar-button {
    @apply !text-white;
  }

  .p-menubar {
    @apply !px-4 flex justify-between items-center;
  }

  .p-menubar-end {
    display: none;
  }

  .p-menubar .p-menubar-root-list .p-menuitem a {
    @apply border-b-[1px] border-black z-[9999];
  }

  .p-menubar .p-menubar-root-list .p-menuitem a {
    @apply p-6 block;
  }

  .p-menubar-root-list {
    @apply w-full h-screen backdrop-blur-[-8px] !bg-white;
  }
}

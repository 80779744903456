.p-dropdown-item.p-highlight {
  background-color: #889 !important;
}

.p-dropdown-item {
  @apply font-main;
}

.p-dropdown {
  height: 2rem;
}

.p-dropdown.p-focus {
  border: 1px solid #ced4da !important;
  box-shadow: 0 0 0 0.2rem rgba(21, 87, 36, 0.5) !important;
}

.p-dropdown-label {
  @apply font-main;
  padding: 0.2rem 0.75rem;
}

.p-dialog {
  border-radius: 1.5rem !important;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  box-shadow: none !important;
}

.p-dialog-header {
  padding: 2rem 2rem 1rem 2rem !important;
  border-bottom: none !important;
  border-radius: 1.5rem 1.5rem 0 0 !important;
}

.p-dialog-content {
  padding: 0 2rem 1.5rem 2rem !important;
  border-radius: 0 0 1.5rem 1.5rem !important;
}

/*  w => wrapper */

.w {
  @apply min-h-screen  bg-fixed bg-center bg-no-repeat bg-origin-border bg-cover sm:bg-none w-full h-full relative;
}

.w-container {
  @apply flex flex-col  container mx-auto md:pt-[60px] md:pb-[30px]   md:px-16;
}

.w-media {
  @apply rounded-b-[40px] md:hidden bg-cover  relative w-full h-[342px] bg-fixed bg-center bg-no-repeat bg-origin-border overflow-hidden flex justify-center items-center;
}

.w::after,
.w-media::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background-image: linear-gradient(to left, #001008d3, #e8ecea27);
}

.w-card-container {
  @apply max-w-full lg:max-w-[43%];
}

.w-card {
  @apply w-full md:relative md:rounded-lg flex flex-col px-4 md:py-2 shadow-none md:shadow-md z-20;
}

.w-logo-d {
  @apply hidden md:block mx-2;
}

.w-title-container {
  @apply mx-2 flex flex-row justify-start gap-2 items-baseline md:my-4;
}

.w-title {
  @apply text-2xl md:text-2xl font-main font-bold;
}

.w-line {
  @apply hidden md:block h-[4px] w-1/2 bg-black;
}
@media (max-width: 767px) {
  .w {
    background-image: none !important;
  }
  .w::after {
    display: none;
  }
}

/* q-f-a =>   questionsForAnswer */

@media (max-width: 800px) {
  .q-f-a-s {
    @apply bg-white p-5  rounded-tr-xl rounded-tl-xl z-20;
  }
  .q-f-a-s ul li {
    @apply py-4;
  }
  .p-dialog .p-dialog-content {
    @apply !rounded-none;
  }

  .dialog {
    @apply fixed bottom-0;
  }
}

/* f => footer */

.f-wrapper {
  @apply px-6 py-16 text-black md:px-16;
}

.f-container {
  @apply grid grid-cols-1 gap-8 md:grid-cols-2 md:gap-12 lg:grid-cols-3 lg:gap-3 justify-items-center;
}

.f-icons {
  @apply flex flex-row items-center gap-6 mt-8 lg:flex-row-reverse text-primary;
}

.f-nav {
  @apply flex-col hidden w-full text-2xl lg:flex lg:w-auto;
}

.f-nav-text {
  @apply mb-2 font-bold text-primary;
}

.f-copy-right {
  @apply hidden px-6 py-1 text-white bg-transparent lg:flex md:px-16 bg-primaryDark;
}

.f-copy-right-mobile {
  @apply lg:hidden;
}

.seperated {
  margin-top: 18px;
}

.footer-square {
  max-width: 300px;
}

.d-box {
  @apply flex flex-row gap-4 w-full;
  justify-content: center;
  align-items: flex-start;
}

@media screen and (max-width: 1330px) {
  .d-box {
    flex-direction: column;
  }
}

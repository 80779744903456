/*  c => consultation  */

.c-container {
  @apply flex flex-col gap-4 my-8 border-t-[.2px] border-[rgba(112,112,112,0.24)] py-4 shadow-md md:shadow-none px-2 rounded-lg md:rounded-none;
}

.c-header-intro {
  @apply flex items-center justify-between;
}

.c-imgs {
  @apply flex flex-row items-center gap-4;
}

.c-user-img {
  @apply w-[66px] h-[66px] rounded-full overflow-hidden;
}

.c-title-co {
  @apply text-xl text-black font-main;
}

.c-date {
  @apply text-black font-thin text-sm text-right;
  direction: ltr;
}

.c-menu {
  @apply relative;
}

.c-menu-btn {
  @apply bg-primary/10 rounded-md px-2 py-1 ml-0 md:ml-16;
}

.c-content {
  @apply my-2 border-r-4 border-primary px-4 rounded-sm font-main;
}
.c-btn {
  @apply my-2 text-center md:text-right;
}
/*  ci => consultation info */

.ci-info {
  @apply order-1 md:order-2 w-full md:w-1/4 md:shadow-md p-2 bg-white;
}

.ci-header {
  @apply hidden md:block text-center;
}
.ci-header .ci-title {
  @apply text-4xl font-main font-bold;
}

.ci-body {
  @apply flex flex-row md:flex-col justify-evenly md:justify-between gap-2 mt-8 md:border-t-2 md:border-b-2 py-2 md:border-[rgba(112,112,112,0.24)] bg-pageBackground md:bg-white;
}

.ci-calendar {
  @apply w-full hidden md:block overflow-hidden;
}

.ci-calendar .p-disabled {
  @apply opacity-100;
}

.ci-calendar .p-datepicker-prev,
.ci-calendar .p-datepicker-next {
  @apply hidden;
}

.ci-title {
  @apply flex flex-col md:flex-row  items-center justify-around;
}
.ci-title-md-only {
  @apply hidden md:flex flex-col md:flex-row  items-center justify-around;
}
.ci-title :last-child {
  @apply md:bg-[#f9f9f9] md:rounded-lg md:px-3;
}
.p-datepicker table td > span {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 12px;
}

.p-calendar .p-datepicker {
  @apply border-none;
}
.p-datepicker table td {
  @apply p-0 py-2;
}
.p-datepicker table td.p-datepicker-today > span,
.p-datepicker:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):hover,
.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
  @apply bg-primary text-white rounded-full;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.35;
}

.p-datepicker-prev .p-link .p-datepicker-prev-icon {
  @apply text-primary;
}

/*  cc => consultation control */

.cc-container {
  @apply flex flex-col gap-4 my-8 border-t-[.2px] border-[rgba(112,112,112,0.24)] py-4 shadow-md md:shadow-none px-2 rounded-lg md:rounded-none;
}

.cc-header {
  @apply flex items-center justify-between;
}

.cc-container-inner {
  @apply flex flex-row items-center gap-4;
}

.cc-inner {
  @apply w-[66px] h-[66px] rounded-full overflow-hidden;
}

.cc-body {
  @apply my-2 border-r-4 border-primary px-4 rounded-sm font-main;
}

.cc-btn {
  @apply my-2 text-center md:text-right;
}
.cc-date {
  @apply text-black font-thin text-sm text-right;
  direction: ltr;
}
.cc-title {
  @apply text-xl text-black font-main;
}

/*  c => consultation */
